import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(-1)} // Navigate to the previous page
      style={{
        cursor: 'pointer', // Makes the text look clickable
        fontWeight: 'normal', // Default font weight
        textAlign: 'left',
        margin: '1rem',
        transition: 'font-weight 0.2s ease', // Smooth hover transition
      }}
      onMouseEnter={(e) => (e.target.style.fontWeight = 'bold')} // Bold on hover
      onMouseLeave={(e) => (e.target.style.fontWeight = 'normal')} // Revert on mouse out
    >
      Back
    </div>
  );
};

export default BackButton;
