import React from 'react';
import BackButton from './components/BackButton';
import './favorites.css';


const Favorites = () => {
    return (
        <div className='sections'>
            <BackButton></BackButton>
          <h1>The bricks that built me</h1>
          <p>2011 Derrick Rose made me want to play sports</p>
          
        </div>
      );
};

export default Favorites;
