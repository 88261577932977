import React from 'react';
import Me from './components/Me.js';

import About from './components/About';
import Portfolio from './components/Portfolio';
import Misc from './components/Misc.js';
import Picture from './components/Picture.js'
import Career from './components/Career.js';


import './styles.css';

const Home = () => {
    return (
        <div className="Home">
            <Me />
            <About />
            <Career />
            <Portfolio />
            <Misc />
            <Picture />
        </div>
    );
};

export default Home;