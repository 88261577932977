import React, { useState } from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ password, children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  if (!isAuthenticated) {
    const userPassword = prompt("Sorry, this is private. Only for friends!:");
    if (userPassword === password) {
      setIsAuthenticated(true); // Grant access
    } else {
      alert("Incorrect password. Redirecting to the home page.");
      return <Navigate to="/" replace />; // Redirect to home page
    }
  }

  return children; // Render the protected content
};

export default ProtectedRoute;
