import React from 'react';
import { Link } from 'react-router-dom';
const Misc = () => {
    return (
        <section id="misc" className="misc">
            <h2>Misc</h2>
            <p>I grew up working in my parent's restaraunt, I love food</p>
            <p>right now, I am reading: Their Eyes Were Watching God</p>
            <p>go <Link  to="https://www.brightonandhovealbion.com/" className="brighton-link">
                        seagulls
                    </Link> </p>
        </section>
    );
};

export default Misc;