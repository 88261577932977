import React from 'react';
import BackButton from './components/BackButton';

import './tenets.css';

const Tenets = () => {
    return (
        <div className='textfiles'>
            <BackButton></BackButton>
          <h1>tenets</h1>
          <p>always hope</p>
        </div>
      );
};

export default Tenets;
