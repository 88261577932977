import React from 'react';
import BackButton from './components/BackButton';

import './writing.css';

const Writing = () => {
  return (
    <div className='writingPage'>
        <BackButton></BackButton>
      <h1>Pieces I want to share</h1>
      <p>Work in Progress</p>
    </div>
  );
};

export default Writing;
