import React from 'react';
import { Link } from 'react-router-dom';

const Me = () => {
    return (
        <section id="me" className="me">
            <h1>Andrew Lanpouthakoun (he/him)</h1>
            <p>from the <Link  to="https://en.wikipedia.org/wiki/Palatine,_Illinois" className="p-link">
                        p
                    </Link>, now @ Stanford</p>
        </section>
    );
};

export default Me;
