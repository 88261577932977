import React from 'react';
import BackButton from './components/BackButton';


const Projects = () => {
    return (
        <div className='projectPage'>
            <BackButton></BackButton>
          <h1>Projects!</h1>
          <p>Work in Progress</p>
        </div>
      );
};

export default Projects;
