import React from "react";
import "./Picture.css"; // Import the CSS file for styling
import image1 from './assets/PicturesOfMe/IMG_0545.JPG'

const Picture = () => {
  const pictures = [
    { src: image1, alt: "Image 1" },
  ];

  return (
    
    <div className="image-gallery"> 
     
      {pictures.map((picture, index) => (
        <img key={index} src={picture.src} alt={picture.alt} className="gallery-image" />
      ))}
      <p>Here's me!</p> 
    </div>
    
  );
};

export default Picture;
