import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Writing from './pages/Writing';
import Projects from './pages/Projects';
import Favorites from './pages/Favorites';
import Tenets from './pages/Tenets';
import ProtectedRoute from './pages/components/ProtectedRoute';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/writing" element={<Writing />} />
      <Route path="/tenets" element={<Tenets />} />
      <Route
        path="/favorites"
        element={
          <ProtectedRoute password="jessica">
            <Favorites />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default App;

